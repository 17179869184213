import React from 'react';
import Typography from "@material-ui/core/Typography";

import '../Common.css';
import yellowTic16 from "../images/curved-yellow-tic-16px10-.png";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginLeft: 140,
    },
    paperImage: {
        height: 25,
        width: 20,
        background : '#f1f1f1',

        boxShadow: 'none'
    },
    paperText: {
        alignItems:"stretch",
        marginLeft: 1,
        background : '#f1f1f1',
        boxShadow: 'none',
        textAlign: 'left',

    },
    control: {
        padding: theme.spacing(2),
    },
    divCont: {
        display: "flex",
    },
    divFixed: {

    },
    divFlexItem: {
        flexGrow: "1",
        marginLeft: 4,
    }

}));

export const CheckBulletText= (props) => {
    const { text} = props;
    const classes = useStyles();
    return (
        <div className={classes.divCont}><div><img src={yellowTic16} alt="headline strip" align="bottom"/> </div><div className={classes.divFlexItem}><Typography paragraph>{text}</Typography></div>
        </div>
    );
};

