import React from 'react';

import './App.css';
import MainNavigator from './components/MainNavigator';
import MarerialUIDrawer from './components/MainDrawerNavigator';
import MainDrawer from './components/MainMenuNav';
import MiniDrawer from './components/layout';
import Home from './components/Home';
import Service from './components/Service';
import About from './components/About';
import Contact from './components/Contact';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';



function App() {
  return (

      <div className="App">
        <Router>
        <MiniDrawer/>

        </Router>
      </div>

  );
}

export default App;
