import React from 'react';
import '../Common.css';

import Service from './Service';
import About from './About';
import Contact from './Contact';
import Typography from "@material-ui/core/Typography";
import headlineStrip from "../images/headline-stripe.png";

/*function Home() {
  return (
    <div>
        <section>
            <article className="article1">
                <h1 className="tuneitcon-title">Tune ITCon</h1>
                <p className="para-title">Entertainment – Social Media – IT – Business Process
                   Management – <br/>Busines Consulting Marketing Services</p>

                <p className="para-gen-content">Are you a startup, micro, small or Medium Size Enterprise (SME),
                    <br/> and would like to build a sustainable business? We are here to help you.
                    <br/><br/> Check out our wide range of enterprise services that are tailored
                    <br/> to enhance your business and together, we can drive your business to success.</p>
            </article>
        </section>
    </div>
  );
}

export default Home;*/

const styles = {
    home: {
        padding: '0px',
        textAlign: 'center',
    }
};
export default class Home extends React.Component {
    render() {
        return <div id="homeId" style={styles.home}>

            <section>
                <article className="article">

                <Typography paragraph>
                    <h1 className="para-title">Entertainment – Social Media – IT – Business Process Management – Busines Consulting – Digital Marketing Services</h1>
                    <img src={headlineStrip} alt="headline strip" className="headline-indicator-image" align="left"/>
                    <br/>
                    <p className="para-gen-content">Are you a startup, micro, small or medium-size enterprise (SME),
                        <br/> and would like to build a sustainable business? <br/>
                        We are here to help you.<br/><br/>
                        We offer a wide range of enterprise services that are tailored to enhance your business and, together, we can drive your business to success.</p>
                </Typography>
                </article>
            </section>
            <Service/>
            <About/>
            <Contact/>
        </div>;
    }
}