import React from 'react';
import Typography from "@material-ui/core/Typography";

import '../Common.css';
import headlineStrip from "../images/headline-stripe.png";
import yellowTic16 from "../images/curved-yellow-tic-16px10-.png"
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import {red} from "@material-ui/core/colors";

import {CheckBulletText} from "./CommonComponents";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: 140,
  },
  paperImage: {
    height: 25,
    width: 20,
    background : '#f1f1f1',

    boxShadow: 'none'
  },
  paperText: {
    alignItems:"stretch",
    marginLeft: 1,
    background : '#f1f1f1',
    boxShadow: 'none',
    textAlign: 'left',

  },
  control: {
    padding: theme.spacing(2),
  },
  divCont: {
  display: "flex",
},
 divFixed: {

},
 divFlexItem: {
  flexGrow: "1",
   marginLeft: 2,
}

}));



function Service() {
  const classes = useStyles();
  return (
    <div>
      <section>
        <article className="article">
        <Typography paragraph>
          <h1 className="page-title">Our Services</h1>
          <img src={headlineStrip} alt="headline strip" className="headline-indicator-image" align="left"/>
          <br/>


          <p className="para-gen-content">We provide professional and top-quality services for startups and SMEs<br/><br/>


            <CheckBulletText text={'All around IT services'} />

            <CheckBulletText text={'Business IT infrastructure checks and consultation'} />

            <CheckBulletText text={'Business IT architecture'} />

            <CheckBulletText text={'Internet Presence and Architecture. We bring your business to the internet'} />

            <CheckBulletText text={'Internet / Digital Marketing, Advertising and Sales'} />

            <CheckBulletText text={'Content / Copywriting'} />

            <CheckBulletText text={'SEO (search engine optimization)'} />

            <CheckBulletText text={'Social Media Integration (Facebook, Twitter, LinkedIn, Pinterest, Instagram and many other)'} />

            <CheckBulletText text={'Analytics'} />

            <CheckBulletText text={'UX / UI design'} />

            <CheckBulletText text={'Software Development'} />

            <CheckBulletText text={'Design and develop AI services (Artificial Intelligence / Machine Learning apps)'} />

            <CheckBulletText text={'Project Management & Coordination'} />

            <CheckBulletText text={'Quality Assurance'} />

            <CheckBulletText text={'Set up complete off-shore service teams that are cost-efficient and compatible, in India, including other Asian countries. Software developers (desktop and mobile front-end and back-end developers), DevOps, designers, 24-hours customer service and support.'} />

            <CheckBulletText text={'Business Management Process. BPM services. We will analyze your business and optimize your processes, ensuring cost-efficient, targeted and sustainable growth for your business'} />

            <CheckBulletText text={'Chief Technology [CTO] and Chief Information Officer [CIO] Services. For startups, and small and medium businesses that cannot afford a full-time CTO or CIO'} />

          </p>
        </Typography>
        </article>
      </section>
    </div>
  );
}

export default Service;